.area-table {
  &__headline {
    text-transform: uppercase;
    color: $color-grey;
    font-weight: bold;
    font-size: 1em;
    line-height: 1.5em;
  }
  &__items {
    border: 1px solid $color-grey-1;
  }
  &__item {
    margin: 15px;
    &:not(:last-child) {
      border-bottom: 1px solid $color-grey-1;
    }
  }
}