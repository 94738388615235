/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

@function color($color) {
    @return map-get($colors, $color);
}

@function toREM($fontSize) {
    @return #{($fontSize / $font-size-base)}rem;
}

@function toPX($points) {
    @return #{($points * 1.333333)}px;
}

@function toREMfromPT($points) {
    @return #{(($points * 1.333333) / $font-size-base)}rem;
}