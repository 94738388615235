.area-accordion__content {
  margin-bottom: 45px;
}

.accordion {
  &__section {
    border: 0;
    margin-bottom: 5px;
    .brick__wrapper {
      padding: 30px 0;
    }
  }

  &__content-container {
    border: 1px solid $color-grey;
  }
  &__button {
    text-transform: uppercase;
    font-weight: bold;
    background: none;
    width: 100%;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 50px;

    &:hover {
      text-decoration: none;
    }
    &:focus {
      border: 0;
      box-shadow: none !important;
    }
  }

  &__header {
    font-size: 20px;
    border: 0;
    border-radius: 0 !important;
    padding: 0;
    background-color: $color-grey;
    .accordion__symbol--plus {
      display: none;
    }

    .accordion__symbol--minus {
      fill: $color-white;
    }

    button {
      fill: $color-white;
      color: $color-white;
    }
  }

  &__symbol {
    background: $color-white;
    border-radius: 15px;
    height: 15px;
    width: 15px;
    margin: -3px 46px;
    line-height: 15px;
    display: inline-block;
    .icon {
      position: absolute;
      margin: auto;
      height: 9px;
      width: 15px;
      top: 8px;
      fill: $color-primary;
      vertical-align: middle;
    }
  }

  &__header.collapsed {
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0.03);
    .accordion__symbol {
      background: $color-primary;
      .icon {
        fill: $color-white;
      }
    }
    .accordion__symbol--minus {
      display: none;
    }
    .accordion__symbol--plus {
      display: inline-block;
    }

    button {
      fill: $color-primary;
      color: $color-primary;
    }
  }
}