.area-contact-persons.brick {
  .contact-person__wrapper {
    margin: 40px 0;
  }

  .contact-person__image {
    max-height: inherit;

    @include media-breakpoint-down(md) {
      padding-left: 15px;
    }

    @include media-breakpoint-down(sm) {
      padding-right: 15px;
    }
  }

  @include media-breakpoint-down(sm) {
    /*.contact-person__position-wrapper {
      position: relative;
      margin-top: 0;
      margin-bottom: 15px;
      left: 0;
      @include translate-rotate(0,0,0);
    }*/
  }
}