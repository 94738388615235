.area-teaser {

  .brick__wrapper {
    padding-bottom: 90px;
  }

  .teaser-items {
    .pimcore_tag_block {
      display: flex;
      flex-wrap: wrap;
    }
    &--twoColumn {
      .pimcore_block_entry {
        width: 50%;
        clear: none;
        padding: 0 15px;
      }
    }
    &--threeColumn {
      .pimcore_block_entry {
        width: 33.3333333333%;
        clear: none;
        padding: 0 15px;
      }
    }
    &--panorama {
      .pimcore_block_entry {
        width: 100%;
        clear: none;
        padding: 0 15px;
      }
    }
  }


  &__items {
    padding: 0;

    .teaser-object {
      height: 100%;
      background: $color-white;
      position: relative;
      display: flex;
      display: -webkit-flex;
      display: -moz-flex;
      flex-direction: column;

      &--date {
        background: none;
      }

      &--news {
        border: 2px solid color(primary);
        .teaser-object__headline {
          margin-top: 15px;
        }
      }

      &--manuel {
        .teaser-object__publish-date-wrapper {
          margin-top: -30px;
        }
      }

      &__publish-date-wrapper {
        text-align: center;
        margin-top: -15px;
        transform: translateX(-50%);
        left: 50%;
        position: absolute;
        z-index: 2;
        &--date {
          left: 0;
          text-align: center;
          margin: 0;
          transform: none;
          top: 15px;
        }
      }

      &__publish-date {
        font-size: 14px;
        text-transform: uppercase;
        padding: 4px 12px;
        background: $color-primary;
        color: $color-white;
        font-weight: $font-weight-medium;
        &--date {
          line-height: 22px;
          padding: 0;
        }
        &--top, &--bottom {
          padding: 4px 6px;
        }
        &--top {
          background: color(primary);
        }
      }

      &__inner {
        padding: 15px 30px 0;
        &--date {
          padding: 15px 75px 30px;
        }
      }
      &__image {
        width: 100%;
        overflow: hidden;
        transition: all .3s ease;
        img {
          transition: all .3s ease;
        }
        &:hover {
          img {
            transform: scale(1.2);
          }
        }
      }
      &__label {

      }
      &__sub-headline {

      }
      &__headline {
        line-height: 1.4em;
        font-size: 1.3em;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        &--date {
          line-height: 1.4em;
          color: $color-grey;
          font-size: 1em;
        }
      }
      &__text {
        line-height: 1.4em;
        color: $color-grey;
      }
      &__footer {
        padding: 15px 30px;
        display: flex;
        display: -webkit-flex;
        display: -moz-flex;
        margin-top: auto;
        margin-bottom: 0;
      }

      &__link {
        padding: 0 10px;
        text-transform: uppercase;
        .icon {
          margin-left: 10px;
          line-height: 30px;
        }
        button {
          font-size: 0.8em;
          line-height: 30px;
        }
        &--date {
          margin-left: 0;
          .icon {
          }
          background: none !important;
          button {
            padding: 0;

            svg {
              fill: color(primary) !important;
            }
            &:hover {
              background: none !important;
              color: $color-black !important;
              svg {
                fill: $color-black !important;
              }
            }
          }
        }
      }
    }


    &--slider {
      position: relative;
      padding: 0 0;
      padding-bottom: 30px;
      .teaser-items__row {
        margin: 0;
        outline: none;
      }
      .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        outline: none;
        width: 30px;
        height: 30px;
        svg {
          width: 30px;
          height: 30px;
        }
        &.prev {
          left: -30px;
        }
        &.next {
          right: -30px;
        }
      }
    }

    &--masonry {
      margin-left: -15px;
      margin-right: -15px;
      .teaser-item {
        display: inline-block;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 0;
        margin-bottom: 15px;
        float: left;
        margin-bottom: 60px;

        &--twoColumn {
          width: 50%;
        }
        &--threeColumn {
          width: 33.333333%;
        }
      }
    }

    .slick-dots {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        button {
          width: 12px;
          height: 12px;
          background: $color-grey;
          border-radius: 12px;
          font-size: 1px;
          text-indent: -9999px;
          margin: 0 3px;
          border: none;
          padding: 0;
          cursor: pointer;
          outline: none;
        }
        &.slick-active button {
          background: color(primary);
        }
      }
    }

    .teaser-items__row:not(:first-child) {
      margin-top: 60px;
    }
  }
}

@include media-breakpoint-down(md) {
  .teaser-item {
    margin-bottom: 30px;
  }
}

.area-teaser__items .teaser-object--manuel.teaser-object--date .teaser-object__publish-date-wrapper {
  margin-top: 0px;
}