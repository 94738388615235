.area-panorama {
  &__content {
    width: 100%;
    position: relative;
  }
  &__headlines {
    position: absolute;
    top: 40px;
    &--text-left {
      left: 40px;
      text-align: left;
    }
    &--text-right {
      right: 40px;
      text-align: right;
    }
    &--text-center{
      transform: translateX(-50%);
      left: 50%;
      text-align: center;
    }
  }
  &__headline {
    color: $color-white !important;
    background: color(primary-bright);
    padding: 0 20px;
    opacity: 0.8;
  }
  img {
    width: 100%;
  }
}