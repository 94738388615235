header .main-sub-navigations {
  color: $color-grey;

  .main-sub-navigation-wrapper {
    max-height: 0;
    overflow: hidden;
    @include transition(ease max-height 100ms);

    &.active {
      max-height: 2000px;
      @include transition(ease max-height 2s);
    }

    .main-sub-navigation {
      .container {
        border-top: 1px solid $color-white;
        padding-top: 35px;
        padding-bottom: 35px;
      }
      ul.sub-nav {
        @include list-unstyled;

        li {
          width: 30%;
          margin: 0;

          a {
            line-height: 1.4;
            padding-top: 6px;
            padding-bottom: 6px;
            font-family: $font-family-base;
            color: $color-grey;
            position: relative;
            padding-left: 40px;

            span.icon {
              position: absolute;
              left: 0;
              top: 0;
            }

            svg {
              fill: $color-grey;
            }
            &.active {
              font-family: $font-family-base;
            }

            &:hover {
              color: $color-primary;;
            }
          }

          /* one item */
          &:nth-child(1):nth-last-child(1) {
            width: 100%;
          }

          /* two items */
          &:nth-child(1):nth-last-child(2),
          &:nth-child(2):nth-last-child(1) {
            width: 49.5%;
          }
        }
      }

      .navigation {

        &__title {
          font-size: 19px;
          text-transform: uppercase;
          font-family: $font-family-base;
          padding: 0;
          color: $color-grey;
          margin-bottom: 25px;
        }
        &__description {
          font-family: $font-family-base;
        }
      }
    }
  }
  .tag-nav {
    a.h4 {
      display: block;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        list-style: none;
        padding: 0;
      }
    }
  }
}

.btn.navigation__read-more-button {
  margin-top: 25px;
}