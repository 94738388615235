.area-section {
  .brick__header {
    padding-top: 30px;
  }
  .brick__header--sub-headline {
    margin-bottom: 0;
  }

  .brick .brick__wrapper {
    padding: $brick-in-brick-padding 0;
  }
}