.area-news__wrapper {
  .brick__header {
    border-bottom: 1px solid $color-grey-1;
    margin-bottom: 15px;
    &--date {
      margin: 0;
    }
  }
}

.teaser-object {
  &__image-wrapper {
    padding: 0;
  }
}

.area-news-list__wrapper {
  margin: 30px 0;
  .brick__header {
    &--headline {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}

.area-news-list__item {
  margin-bottom: 30px;
}

.area-news-list__headline {
  line-height: 2em;
  font-size: 3em;
  color: $color-grey;
}

.area-teaser__items .teaser-object__link {
  padding: 0;
}

.pagination {
  .link-with-icon > span.icon {
    margin-left: 0;
  }
}