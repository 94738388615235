#siteWrapper {
  max-width: $site-wrapper-width;
  padding-top: $header-height-desktop;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
  -webkit-box-shadow: 0 5px 10px 0 rgba(134,134,134,.5);
  box-shadow: 0 5px 10px 0 rgba(134,134,134,.5);

  &.top-navigation {
    padding-top: 66px;
  }
  @include media-breakpoint-up(lg) {
    &.top-navigation {
      padding-top: $extended-header-height-desktop;
    }
  }

  .siteWrapper__inner {
    position: relative;

    .siteWrapper__bg-image {
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: 100% 100%;
      width: 100%;
      height: 100%;
      z-index: -1;
      position: absolute;
      pointer-events: none;
      opacity: 0.4;
    }
  }
}

.login-part {
  padding: 50px 0;

  form {
    label {
      font-weight: $font-weight-bold;
    }

    input {
      display: block;
    }

    .button-group {
      margin-top: $default-space / 2;
    }
  }
}

.news-event-overview {
  .area-teaser__items.area-news-list__wrapper {
    margin-top: 0;
  }
}

.bricks-wrapper {
  margin-top: -15px;
  padding-top: 15px;
  position: relative;

  &__background-image {
    position: absolute;
    z-index: -1;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
  }
}

#pc-cookie-notice {
  padding: 0 !important;

  #pc-button {
    top: 1px !important;

    button {
      line-height: 1.5 !important;
    }
  }
}