$mobile-menu-button-width: 24px;
$mobile-menu-button-height: 20px;

.mobile-menu-button {
	width: $mobile-menu-button-width;
	height: $mobile-menu-button-height;
	position: relative;
	margin: (60px - $mobile-menu-button-height) / 2 0;
	margin-left: auto;
	margin-right: 0;
	transform: rotate(0deg);
	@include transition(.5s ease-in-out);
	cursor: pointer;

	span {
		border-radius: $mobile-menu-button-height / 5;
		left: 0;
		display: block;
		position: absolute;
		height: $mobile-menu-button-height / 5;
		width: 50%;
		opacity: 1;
		transform: rotate(0deg);
		@include transition(.25s ease-in-out);
		background-color: $color-white;

		&:nth-child(even) {
			left: 50%;
			border-radius: 0 ($mobile-menu-button-height / 5) ($mobile-menu-button-height / 5) 0;
		}

		&:nth-child(odd) {
			left: 0;
			border-radius: ($mobile-menu-button-height / 5) 0 0 ($mobile-menu-button-height / 5);
		}

		&:nth-child(1), &:nth-child(2) {
			top: 0;
		}

		&:nth-child(3), &:nth-child(4) {
			top: ($mobile-menu-button-height / 5) * 2;
		}

		&:nth-child(5), &:nth-child(6) {
			top: ($mobile-menu-button-height / 5) * 4;
		}
	}
}

body.mobile-menu-open {
	.mobile-menu-button {
		span {
			&:nth-child(1), &:nth-child(6) {
				transform: rotate(45deg);
			}

			&:nth-child(2), &:nth-child(5) {
				transform: rotate(-45deg);
			}

			&:nth-child(1) {
				left: $mobile-menu-button-width * (1/12);
				top: $mobile-menu-button-height * (7/45);
			}

			&:nth-child(2) {
				left: calc(50% - #{($mobile-menu-button-width * (1/12))});
				top: $mobile-menu-button-height * (7/45);
			}

			&:nth-child(3) {
				left: -50%;
				opacity: 0;
			}

			&:nth-child(4) {
				left: 100%;
				opacity: 0;
			}

			&:nth-child(5) {
				left: $mobile-menu-button-width * (1/12);
				top: $mobile-menu-button-height * (29/45);
			}

			&:nth-child(6) {
				left: calc(50% - #{($mobile-menu-button-width * (1/12))});
				top: $mobile-menu-button-height * (29/45);
			}
		}
	}
}