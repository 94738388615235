$dimension: 300px;
$dimension-mobile: 200px;
$inner-circle-distance: 20px;
.callout {
  position: absolute;
  z-index: 5;
  right: 30px;
  top: 70px;
  width: $dimension-mobile;

  &__wrapper {
    z-index: 100;
    width: 100vw;
    max-width: 1920px;
  }

  &--left {
    left: 0;
    right: auto;

    @media (max-width: 575px) {
      right: 40px;
      left: auto;
    }
  }

  &__circle {
    top: $inner-circle-distance / 2;
    left: $inner-circle-distance / 2;
    position: absolute;
    width: $dimension-mobile - $inner-circle-distance;
    height: $dimension-mobile - $inner-circle-distance;
    border: 1px solid $color-white;

  }

  &__container {
    width: $dimension-mobile;
    height: $dimension-mobile;
    box-shadow: 0 0 30px $color-grey;
    z-index: 0;
    overflow: hidden;
    background: linear-gradient(80deg, $color-primary 50%, $color-primary 50.1%);

    .fullwidth-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      z-index: 1;
    }
  }

  &__close {
    position: absolute;
    border: 0;
    right: 10px;
    top: 10px;
    background: none;
    cursor: pointer;
    z-index: 3;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 5px;

    .icon {
      display: inline-block;
      width: 16px;
      height: 21px;
    }

    svg {
      transform: scale(1);
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      @include transition(all 300ms);
    }

    &:hover {
      use {
        fill: $color-white;
      }

      svg {
        transform: scale(1.2);
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        @include transition(all 300ms);
      }
    }
    use {
      fill: $color-white;
    }
  }

  &__inner-wrapper {
    transform: translateY(-50%);
    margin-top: 50%;
    padding: 0 20px;
  }

  &__label {
    color: $color-white;
    text-transform: uppercase;
    font-weight: normal;
    white-space: pre-wrap;
    line-height: 1.2em;
    display: none;
  }

  &__headline {
    width: 165px;
    color: $color-white;
    margin: 50px auto 20px auto;
    font-size: 1.2em;
    line-height: 1.2em;
    white-space: pre-wrap;
    text-transform: uppercase;
  }

  &__link {
    font-weight: normal;
    color: $color-white;
    display: inline-block;
    text-transform: uppercase;
    width: 165px;
    margin: auto;
    font-size: 0.9em;
    &:hover {
      text-decoration: underline;
      color: $color-white;
    }
  }

  @include media-breakpoint-up(lg) {
    width: $dimension;
    right: 70px;
    top: 80px;

    &__circle {
      width: $dimension - $inner-circle-distance;
      height: $dimension - $inner-circle-distance;
    }
    &__container {
      width: $dimension;
      height: $dimension;
    }
    &__headline {
      font-size: 2.2em;
      line-height: 1.2em;
      width: 230px;
    }

    &__label {
      display: block;
      font-weight: lighter;
      font-size: 1.2em;
    }

    &__link {
      width: 230px;
    }
    &__close {
      &--left {
        right: 20px;
        top: 20px;
      }
    }
  }
}