.brick {
    &__wrapper {
        padding: $brick-padding 0;
    }

    &:last-of-type {

    }

    &__header {
        &--reduced {
            margin-bottom: ($brick-header-bottom-space / 2);
        }
        &--headline,
        &--sub-headline,
        &--info-text {
            &, > * {
                width: 100%;
            }
            line-height: 1em;
        }
        &--headline {
            line-height: 1em;
            color: $color-primary;
            text-transform: uppercase;
            font-size: 1.8em;
            margin: 25px 0 35px;

            .headline-default {
                line-height: 1em;
                color: $color-primary;
                text-transform: uppercase;
                font-size: 28.8px;
                margin: 25px 0 35px;
                font-weight: $font-weight-normal;
            }
        }
        &--sub-headline {
            color: color(primary);
            line-height: 1.5em;
            font-size: 1.45em;
            font-weight: normal;
            margin-top: -15px;
        }
    }

    &__footer {
        margin-top: $brick-footer-top-space;
        margin-top: $brick-footer-bottom-space;
        .brick__footer {
            margin-top: ($brick-footer-top-space / 2);
        }
        .cta-button-item {
            &__sub-text {
                margin: 5px 0 0 0;
            }
        }
        &.justify-content-start {
            .cta-button-item {
                margin: 0 10px 0 0;
                text-align: left;
            }
        }
        &.justify-content-center {
            .cta-button-item {
                margin: 0 10px;
                text-align: center;
            }
        }
        &.justify-content-end {
            .cta-button-item {
                margin: 0 0 0 10px;
                text-align: right;
            }
        }

    }
}

select.form-control:not([size]):not([multiple]) {
    height: auto !important;
}