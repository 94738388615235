/*-------------------------------------------------------------------------*/
/*	Global */
/*-------------------------------------------------------------------------*/

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    line-height: 1.5em;
}

.hidden {
    display: none !important;
}

/******************
 * COOKIE POLICY
 ******************/
#pc-cookie-notice {

    #pc-button {
        button {

        }
    }
}

/* Change the white to any color ;) */
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
}

.icon {
    display: inline-block;
    > svg {
        width: 100%;
        height: 100%;
    }
}

// tiny element styles
.link-with-icon {
    position: relative;
    display: inline-block;
    vertical-align: top;

    .icon {
        width: 9px;
        height: 9px;
        vertical-align: top;
        line-height: 33px;
        margin-left: 15px;
    }
}

a {
    color: $color-primary;

    &:hover {
        color: $color-primary;
    }
}

.background-primary {
    @include background-color(color(primary), color(background));
}

.background-primary-bright {
    @include background-color(color(primary-bright), color(background));
}

.background-grey {
    background: color(background-shade);
}

.background-light-grey {
    background: $color-grey-3;
}

.background-white {
    background: transparent;
    color: $color-black;
}
.color-primary > *{
    color: color(primary);
    fill: color(primary);
}

.color-white{
    color: color(background);
    fill: color(background);
    &:hover {
        color: color(background);
    }
    a:hover {
        color: color(background);
    }
}

.color-black{
    color: color(black);
    fill: color(black);
    &:hover {
        color: color(black);
    }
    a:hover {
        color: color(black);
    }
}

.center-vertical-md {
    @include media-breakpoint-up(md) {
        @include center-vertical();
    }
}

h1, .h1 {
    font-family: $font-family-base;
    font-weight: $font-weight-medium;
    color: $color-primary;
    line-height: normal;
    font-size: 2.1em;
}

h2, .h2 {
    font-family: $font-family-base;
    font-weight: $font-weight-medium;
    font-size: 1.5em;
    color: $color-primary;
}

h3, .h3 {
    font-family: $font-family-base;
    font-weight: $font-weight-normal;
    line-height: 1.5em;
    font-size: 23px; //1.45em;
    color: color(primary-bright);
}

h4, .h4 {
    font-family: $font-family-base;
    font-weight: $font-weight-medium;
    line-height: 1.2em;
    font-size: #{(16 * 1.33333) / $font-size-base}em;
    color: $color-grey;
}

.icon {
    display: inline-block;

    > svg {
        width: 100%;
        height: 100%;
    }
}

.btn {
    border-radius: 0;

    &.btn-primary, &.btn--ghost {
        color: $color-cta;
        font-weight: $font-weight-bold;
        border: none;
        background: none;
        padding: 0;

        .link-with-icon .icon {
            line-height: 30px;
        }
        svg {
            fill: $color-cta;
        }
        &:hover {
            background: none;
            background-color: transparent;
            color: darken($color-cta, 20%);
            border: none;

            svg {
                fill: darken($color-cta, 20%) !important;
            }
        }
    }
}

.color-cta {
    color: $color-cta;
}

.font-nothing-you-could-do {
    font-family: 'Nothing You Could Do', cursive;
}

.color-primary {
    color: $color-primary;
}

.anchor-wrapper {
    position: relative;
    top: -75px;
}