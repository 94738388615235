
.searchResultsList {
  margin: 45px 0;
}
.searchResult {
  padding: 25px 0;
  border-bottom: 1px solid lightgray;
}

.searchResults {
  margin: 45px 0;
}

.searchResult:first-child {
  padding: 0 0 25px;
}