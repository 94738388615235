.area-wysiwyg {
  color: $color-black;
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__content {
    h1,h2 {
      line-height: normal;
    }
    p {
      line-height: 1.8em;
      margin-bottom: 2em;
    }
    h1,h2,h3 {
      margin-bottom: 1.5rem;
    }
  }

  &__wrapper {
    &.background-white {
      .teaser-object.teaser-object--page {
        border: 2px solid $color-grey-1;
      }
    }
  }

  a {
    color: $color-primary;

    &:hover {
      color: darken($color-primary, 20%);
    }
  }
}

.area-wysiwyg-media {
  &__content {
    h3 {
      margin-bottom: 1.5rem;
    }
    p {
      line-height: 1.8em;
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__base {
    .small-thumbs {

      img {
        padding-top: 15px;
      }

      &.row--small {
        margin-left: -7.5px;
        margin-right: -7.5px;

        [class*="col-"] {
          padding-left: 7.5px;
          padding-right: 7.5px;
        }
      }
    }
  }
}

.area-wysiwyg,
.area-wysiwyg-media {

  @include content-list-style();
}